/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { 
  RiArrowRightSLine, 
  RiFileTextLine, 
  RiSurveyLine, 
  RiBarChartBoxLine, 
  RiMedalLine, 
  RiTeamLine, 
  RiUserSearchLine, 
  RiBuilding4Line, 
  RiToolsLine,
  RiBookOpenLine,
  RiCheckLine
} from "react-icons/ri"

const ResourcesPage = () => {
  // Resource categories with their contents
  const resourceCategories = [
    {
      title: "Employee Engagement",
      icon: <RiTeamLine />,
      description: "Resources to help you measure and improve employee engagement in your organization.",
      resources: [
        {
          title: "Employee Engagement Survey",
          description: "Comprehensive survey template with validated questions to measure and track employee engagement levels.",
          link: "/resources/templates/engagement-survey-template-download",
          icon: <RiSurveyLine />
        },
        {
          title: "Weekly Pulse Survey",
          description: "Short, focused survey for regular check-ins to identify engagement trends and address issues quickly.",
          link: "/resources/templates/pulse-survey-template-download",
          icon: <RiBarChartBoxLine />
        },
        {
          title: "Engagement Action Plan",
          description: "Structured template for creating actionable improvement plans based on engagement survey results.",
          link: "/resources/templates/engagement-action-plan-download",
          icon: <RiFileTextLine />
        },
        {
          title: "Recognition Program Framework",
          description: "Guidelines and structure for implementing an effective employee recognition program.",
          link: "/resources/templates/recognition-program-framework-download",
          icon: <RiMedalLine />
        }
      ]
    },
    {
      title: "Performance Reviews",
      icon: <RiUserSearchLine />,
      description: "Templates and tools to conduct effective performance evaluations and feedback sessions.",
      resources: [
        {
          title: "360-Degree Feedback Template",
          description: "Comprehensive evaluation from multiple stakeholders including managers, peers, and direct reports.",
          link: "/resources/templates/360-feedback-template-download",
          icon: <RiFileTextLine />
        },
        {
          title: "Standard Performance Review",
          description: "Traditional evaluation focusing on performance against goals, competencies, and development areas.",
          link: "/resources/templates/standard-review-template-download",
          icon: <RiFileTextLine />
        },
        {
          title: "Self-Assessment Template",
          description: "Employee evaluation of their own performance, achievements, and development areas.",
          link: "/resources/templates/self-assessment-template-download",
          icon: <RiFileTextLine />
        }
      ]
    },
    {
      title: "Recruitment & Hiring",
      icon: <RiUserSearchLine />,
      description: "Templates and guides to streamline your recruitment process and hire the best candidates.",
      resources: [
        {
          title: "Interview Question Bank",
          description: "Curated list of effective interview questions categorized by skill, role, and competency.",
          link: "/resources/templates/interview-question-bank-download",
          icon: <RiFileTextLine />
        },
        {
          title: "Candidate Evaluation Scorecard",
          description: "Standardized template for objectively evaluating job candidates across key competencies.",
          icon: <RiUserSearchLine />,
          category: "Recruitment",
          link: "/resources/templates/candidate-evaluation-scorecard-download"
        }
      ]
    },
    {
      title: "Organizational Development",
      icon: <RiBuilding4Line />,
      description: "Tools and frameworks for building effective organizational structures and processes.",
      resources: [
        {
          title: "Organizational Health Assessment",
          description: "Diagnostic tool to evaluate the health and effectiveness of your organization's culture and systems.",
          link: "/resources/templates/organizational-health-assessment-download",
          icon: <RiToolsLine />
        }
      ]
    }
  ];

  // List of key resource benefits
  const resourceBenefits = [
    "Professionally designed templates based on HR best practices",
    "Ready-to-use formats that save time and improve consistency",
    "Research-backed methodologies for reliable results",
    "Customizable to fit your organization's specific needs",
    "Mobile-friendly and printable formats for flexibility"
  ];

  return (
    <Layout>
      <SEO 
        title="HR Resources & Templates | Free Tools for Effective People Management"
        description="Access our collection of free HR resources, templates, and tools to improve your people management processes. Download ready-to-use forms, surveys, and frameworks."
        keywords={[
          "HR resources",
          "free HR templates",
          "employee engagement tools",
          "performance review templates",
          "recruitment templates",
          "organizational development tools",
          "HR management resources",
          "people management templates",
          "employee survey templates",
          "recognition program frameworks"
        ]}
      />
      
      {/* Hero Section */}
      <div sx={{
        background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
        py: [4, 5],
        position: 'relative'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            textAlign: 'center',
            maxWidth: '800px',
            mx: 'auto'
          }}>
            <h1 sx={{
              fontSize: ['2rem', '2.5rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary'
            }}>
              HR Resources & Templates
            </h1>
            
            <p sx={{
              fontSize: '1.1rem',
              lineHeight: 1.5,
              mb: 4,
              color: 'text',
              opacity: 0.9
            }}>
              Access our library of free, professionally designed templates, tools, and resources to 
              improve your HR processes and people management practices.
            </p>
            
            <div sx={{
              display: 'flex',
              gap: 3,
              justifyContent: 'center',
              flexWrap: 'wrap'
            }}>
              <a 
                href="#resource-library" 
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'primary',
                  color: 'white',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.15)'
                  }
                }}
              >
                Browse Resources <RiArrowRightSLine />
              </a>
              
              <Link 
                to="/guides" 
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'white',
                  color: 'primary',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  border: '2px solid',
                  borderColor: 'primary',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.05)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}
              >
                View HR Guides <RiBookOpenLine />
              </Link>
            </div>
          </div>
        </div>
      </div>
      
      {/* Main Content */}
      <div sx={{
        bg: 'white',
        py: [4, 5],
        position: 'relative'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3,
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr', '2fr 1fr'],
          gap: 5
        }}>
          {/* Left Column - Main Content */}
          <div>
            {/* Introduction Section */}
            <div sx={{ 
              mb: 5,
              pb: 4,
              borderBottom: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)'
            }}>
              <h2 sx={{
                fontSize: '1.75rem',
                fontWeight: 700,
                color: 'text',
                mb: 3
              }}>
                Our Resource Library
              </h2>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 4
              }}>
                Our collection of HR templates and resources is designed to help you streamline processes,
                save time, and implement best practices in your organization. Each resource is:
              </p>
              
              <ul sx={{
                display: 'grid',
                gridTemplateColumns: ['1fr', '1fr 1fr'],
                gap: 3,
                mb: 4,
                p: 0,
                listStyle: 'none'
              }}>
                {resourceBenefits.map((benefit) => (
                  <li 
                    key={benefit}
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      bg: '#f8f9fa',
                      p: 3,
                      borderRadius: '6px',
                      boxShadow: '0 2px 8px rgba(0,0,0,0.04)'
                    }}
                  >
                    <span sx={{ color: 'primary', mr: 2, pt: '2px' }}>
                      <RiCheckLine />
                    </span>
                    <span sx={{ fontSize: '0.95rem', lineHeight: 1.5 }}>
                      {benefit}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
        
            {/* Categories Section */}
            <div id="resource-library">
              {resourceCategories.map((category) => (
                <div 
                  key={`category-${category.title.toLowerCase().replace(/\s+/g, '-')}`}
                  sx={{ 
                    mb: 5,
                    pb: 4,
                    borderBottom: '1px solid',
                    borderColor: 'rgba(0,0,0,0.06)'
                  }}
                >
                  <div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 3
                  }}>
                    <div sx={{
                      color: 'primary',
                      fontSize: '1.5rem',
                      mr: 2
                    }}>
                      {category.icon}
                    </div>
                    <h2 sx={{
                      fontSize: '1.5rem',
                      fontWeight: 700,
                      color: 'text',
                      m: 0
                    }}>
                      {category.title}
                    </h2>
                  </div>
                  
                  <p sx={{
                    fontSize: '1rem',
                    lineHeight: 1.6,
                    mb: 4,
                    color: 'text'
                  }}>
                    {category.description}
                  </p>
                  
                  <div sx={{
                    display: 'grid',
                    gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                    gap: 4
                  }}>
                    {category.resources.map((resource) => (
                      <Link 
                        key={`resource-${resource.title.toLowerCase().replace(/\s+/g, '-')}`}
                        to={resource.link}
                        sx={{
                          display: 'block',
                          textDecoration: 'none',
                          color: 'text'
                        }}
                      >
                        <div sx={{
                          p: 3,
                          height: '100%',
                          borderRadius: '8px',
                          boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                          transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                          display: 'flex',
                          flexDirection: 'column',
                          '&:hover': {
                            transform: 'translateY(-3px)',
                            boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                          }
                        }}>
                          <div sx={{
                            color: 'primary',
                            fontSize: '1.5rem',
                            mb: 2
                          }}>
                            {resource.icon}
                          </div>
                          
                          <h3 sx={{
                            fontSize: '1.2rem',
                            fontWeight: 700,
                            mb: 2,
                            color: 'text'
                          }}>
                            {resource.title}
                          </h3>
                          
                          <p sx={{
                            fontSize: '0.95rem',
                            mb: 3,
                            lineHeight: 1.5,
                            color: 'text',
                            flexGrow: 1
                          }}>
                            {resource.description}
                          </p>
                          
                          <div sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: 'primary',
                            fontWeight: 600,
                            fontSize: '0.95rem',
                            mt: 'auto'
                          }}>
                            {resource.link === "/contact" ? "Request Template" : "View & Print Template"} <RiArrowRightSLine sx={{ ml: 1 }} />
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          
          {/* Right Column - Sidebar */}
          <div>
            {/* Resource Statistics Box */}
            <div sx={{
              p: 4,
              mb: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
              background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
            }}>
              <h3 sx={{
                fontSize: '1.2rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary'
              }}>
                Resources By The Numbers
              </h3>
              
              <ul sx={{
                listStyle: 'none',
                p: 0,
                m: 0
              }}>
                {[
                  "Over 20+ professionally designed templates",
                  "Used by 500+ organizations worldwide",
                  "Templates save an average of 5+ hours per process",
                  "92% of users report improved process consistency",
                  "Templates updated quarterly with latest best practices"
                ].map((item) => (
                  <li key={item} sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    mb: 3,
                    fontSize: '0.95rem',
                    lineHeight: 1.5
                  }}>
                    <span sx={{ color: 'primary', mr: 2, pt: '3px' }}><RiCheckLine /></span>
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
            
            {/* Request Custom Resources Section */}
            <div sx={{
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
              bg: 'white',
              border: '1px solid',
              borderColor: 'muted'
            }}>
              <h3 sx={{
                fontSize: '1.2rem',
                fontWeight: 700,
                mb: 3,
                color: 'text'
              }}>
                Need a Custom Resource?
              </h3>
              
              <p sx={{
                fontSize: '0.95rem',
                lineHeight: 1.6,
                mb: 3
              }}>
                Don't see what you're looking for? We can create custom templates and tools 
                tailored to your organization's specific needs.
              </p>
              
              <ul sx={{
                listStyle: 'none',
                p: 0,
                m: 0,
                mb: 4
              }}>
                {[
                  "Custom-designed to your specifications",
                  "Aligned with your organizational goals",
                  "Branded with your company elements",
                  "Implementation support available"
                ].map((item) => (
                  <li key={item} sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                    fontSize: '0.9rem'
                  }}>
                    <span sx={{ color: 'primary', mr: 2 }}><RiCheckLine size={14} /></span>
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
              
              <Link 
                to="/contact" 
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'primary',
                  color: 'white',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.15)'
                  }
                }}
              >
                Contact Us <RiArrowRightSLine />
              </Link>
            </div>
            
            {/* Popular Guides */}
            <div sx={{
              mt: 4,
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
              bg: 'white'
            }}>
              <h3 sx={{
                fontSize: '1.2rem',
                fontWeight: 700,
                mb: 3,
                color: 'text'
              }}>
                Popular HR Guides
              </h3>
              
              <div sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 3
              }}>
                {[
                  {
                    title: "Performance Reviews",
                    description: "Complete guide to effective performance evaluations",
                    link: "/guides/performance-reviews",
                    icon: <RiUserSearchLine />
                  },
                  {
                    title: "Employee Engagement",
                    description: "Strategies for a motivated workforce",
                    link: "/guides/employee-engagement",
                    icon: <RiTeamLine />
                  },
                  {
                    title: "Recruitment & Hiring",
                    description: "Best practices for attracting top talent",
                    link: "/guides/recruitment-hiring",
                    icon: <RiUserSearchLine />
                  }
                ].map((guide) => (
                  <Link
                    key={guide.title}
                    to={guide.link}
                    sx={{
                      textDecoration: 'none',
                      color: 'text',
                      display: 'flex',
                      p: 3,
                      borderRadius: '6px',
                      transition: 'background-color 0.2s ease',
                      '&:hover': {
                        bg: '#f8f9fa'
                      }
                    }}
                  >
                    <div sx={{
                      color: 'primary',
                      mr: 3,
                      fontSize: '1.2rem'
                    }}>
                      {guide.icon}
                    </div>
                    <div>
                      <h4 sx={{
                        fontSize: '1rem',
                        fontWeight: 600,
                        m: 0,
                        mb: 1,
                        color: 'primary'
                      }}>
                        {guide.title}
                      </h4>
                      <p sx={{
                        fontSize: '0.85rem',
                        m: 0,
                        lineHeight: 1.5
                      }}>
                        {guide.description}
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ResourcesPage 